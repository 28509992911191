// Override default variables before the import
$enable-gradients: true;
$component-active-bg: #343a40;
$link-color: #ffc107;
$gray-800: rgba(52, 58, 64, .6);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: "nasalization", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./img/thumb-1920-107763.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@include media-breakpoint-down(md) {
  h1.display-3 {
    font-size: 3.5rem;
  }
 }

 .yoda {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 300px;
}

.tooltip-inner {
  background: $warning;
  color: #000
}